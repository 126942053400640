import React, { useState, useRef } from 'react';


import './kyc.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function KYCForm() {
  const initialFormState = {
    pdfFiles: [],
    aadharCardFiles: [],
    panCardFiles: [],
    passportFiles: [],
    cancelChequeFiles: [],
    altContactPersonName: '', 
    altCompanyName: '',       
    bdbIdCardFiles: [],
    photoFiles: [],
    phoneNumber: '', 
    phoneNoAlt: '', 
    phoneNoRef1: '',
    phoneNoRef2: '',
    emailId: '',
    name: '',
    companyName: '',
    contactPersonName: '',
  };
  const [formData, setFormData] = useState({ ...initialFormState, emailId: '' });
  const [selectedDate, setSelectedDate] = useState("");
  const panCardFilesRef = useRef();
  const pdfFilesRef = useRef(null);

  const cancelChequeRef = useRef(null);
  const bdbIdCardRef = useRef();
  const photoFilesRef = useRef(null);
  const passportFilesRef = useRef();
  const aadharCardFilesRef = useRef(null);
  const [showModal, setShowModal] = useState(false);



  const resetForm = () => {
    setFormData(initialFormState);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };


  const handleRemoveFile = (fileType, index) => {
    // Create a new array without the file at the specified index
    const updatedFiles = formData[fileType].filter((_, fileIndex) => fileIndex !== index);
    setFormData({
      ...formData,
      [fileType]: updatedFiles,
    });
    const fileInput = document.getElementById(fileType);
    if (fileInput) {
      fileInput.value = '';
    }
  };
  const [isLoading, setIsLoading] = useState(false); 
  
    // Add this function to handle the custom button click
    const triggerFileInput = () => {
      cancelChequeRef.current.click();
    };
 
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: [...formData[name], ...Array.from(files)],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); 
    const form = new FormData();

    form.append('name', formData.name);
    form.append('phoneNo', formData.phoneNumber);
    form.append('phoneNoAlt', formData.phoneNoAlt);
    form.append('phoneNoRef1', formData.phoneNoRef1);
    form.append('phoneNoRef2', formData.phoneNoRef2);
    form.append('companyName', formData.companyName); // Append company name
    form.append('contactPersonName', formData.contactPersonName); // Append contact person name
    form.append('altContactPersonName', formData.altContactPersonName); // changed to camelCase
    form.append('altCompanyName', formData.altCompanyName);
    form.append('emailId', formData.emailId);       


    // Append files to the FormData object
    formData.pdfFiles.forEach(file => {
      form.append('kyc', file); // Changed to `for
    });
    formData.aadharCardFiles.forEach(file => {
      form.append('aadhar', file);
    });
    formData.panCardFiles.forEach(file => {
      form.append('pan', file);
    });
    formData.passportFiles.forEach(file => {
      form.append('passport', file);
    });
    formData.cancelChequeFiles.forEach(file => {
      form.append('cancelCheque', file);
    });
 
    formData.bdbIdCardFiles.forEach(file => {
      form.append('bdbIdCard', file);
    });
    formData.photoFiles.forEach(file => {
      form.append('selfPhoto', file);
    });

    try {
      const response = await fetch('https://api.aidiamond.in/api/v1/kyc/add', {
        method: 'POST',
        body: form,
      });

      const result = await response.json(); 
      if (response.ok) {
        console.log(result);
        alert(result.message); 
        setShowModal(true); 
        resetForm();
        window.location.href = '/';
      } else {
        console.error('Error submitting form', result);
        alert(result.message); 
      }
    } catch (error) {
      console.error('Error submitting form', error);
      alert('There was a problem submitting your KYC.'); // Fallback error message
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="containerr mt-5">
    {/* Header with Login Button */}
    <div className="logo-container">
        <img src="/PNG-AI.png" style={{ width: 185 }} alt="logo" />
      </div>
    <div className="header">
      
      <h2> BROKER KYC Form</h2>
      
    
    
    </div>

    <h6>Note:Section marked with <span className="required-asterisk" style={{ color: 'red' }}>*</span> are mandatory</h6>
     

      <form onSubmit={handleSubmit}>

      <div className="mb-3">
      <label htmlFor="name" className="form-label">
  1) Name:<span className="required-asterisk" style={{ color: 'red' }}>*</span>
</label>
      <input type="text" className="form-control" maxLength={25} id="name" name="name" placeholder="Name" onChange={handleChange} value={formData.name} />
    </div>

    <div className="mb-3">
          <label htmlFor="emailId" className="form-label">Email ID:<span className="required-asterisk" style={{ color: 'red' }}>*</span></label>
          <input 
            type="email" 
            className="form-control" 
            id="emailId" 
            name="emailId" 
            placeholder="Email ID" 
            onChange={handleChange} 
            value={formData.emailId} 
          />
        </div>

    <div className="row align-items-end"> {/* Align items to the end of the row */}
          <div className="col-md-8">
            <div className="mb-3">
              <label htmlFor="pdfFiles" className="form-label">
                2) KYC File (Accept pdf and word file, max 15 MB)<span className="required-asterisk" style={{ color: 'red' }}>*</span>
              </label>
              <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => pdfFilesRef.current && pdfFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i> {/* Adjust the right margin to create space */}
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.pdfFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.pdfFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, pdfFiles: [] });
          pdfFilesRef.current.value = null;
        }}
        
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="pdfFiles"
      name="pdfFiles"
      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      onChange={handleFileChange}
      multiple
      ref={pdfFilesRef}
    />
  </div>
            </div>
          </div>
          <div className="col-md-4">
            <button
              type="button"
              className="btn custom-secondary mb-3" // Add mb-3 to align with the form group margin
              onClick={() => window.location.href = '/kyc%20form%20for%20broker%20and%20all%20related.docx'}
            >
              Download KYC Form
            </button>
          </div>
        </div>


<div className="mb-3">
  <label htmlFor="aadharCardFiles" className="form-label">
    3) Aadhar Card (Accept pdf,jpeg file, max 15 MB)<span className="required-asterisk" style={{ color: 'red' }}>*</span>
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => aadharCardFilesRef.current && aadharCardFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i>
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.aadharCardFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.aadharCardFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, aadharCardFiles: [] });
          aadharCardFilesRef.current.value = null;
        }}
        
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="aadharCardFiles"
      name="aadharCardFiles"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={aadharCardFilesRef}
    />
  </div>
</div>


        <div className="mb-3">
  <label htmlFor="panCardFiles" className="form-label">
    4) Pan Card (Accept pdf,jpeg file, max 15 MB):<span className="required-asterisk" style={{ color: 'red' }}>*</span>
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => panCardFilesRef.current && panCardFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i> {/* Adjust the right margin to create space */}
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.panCardFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.panCardFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, panCardFiles: [] });
          panCardFilesRef.current.value = null;
        }}
       
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="panCardFiles"
      name="panCardFiles"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={panCardFilesRef}
    />
  </div>
</div>



  <div className="mb-3">
  <label htmlFor="passportFiles" className="form-label">
    5) PASSPORT (Accept pdf,jpeg file, max 15 MB)
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => passportFilesRef.current && passportFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i> {/* Adjust the right margin to create space */}
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.passportFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.passportFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, passportFiles: [] });
          passportFilesRef.current.value = null;
        }}
        // Add space between the text field and button
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="passportFiles"
      name="passportFiles"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={passportFilesRef}
    />
  </div>
</div>


        <div className="mb-3">
  <label htmlFor="cancelChequeFiles" className="form-label">
    6) CANCEL CHEQUE(Accept pdf,jpeg file, max 15 MB) <span className="required-asterisk" style={{ color: 'red' }}>*</span>:
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => cancelChequeRef.current && cancelChequeRef.current.click()}
    >
<i className="fa fa-camera" style={{ marginRight: '8px' }}></i> {/* Adjust the right margin to create space */}
  <span className="slash-style">/</span>
  <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i> {/* Add left margin to create space */}
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.cancelChequeFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.cancelChequeFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => setFormData({ ...formData, cancelChequeFiles: [] })}
         
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="cancelChequeFiles"
      name="cancelChequeFiles"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={cancelChequeRef}
    />
  </div>
</div>

<div className="mb-3">
  <label htmlFor="bdbIdCardFiles" className="form-label">
    7) BDB ID CARD(Accept pdf,jpeg file, max 15 MB) :
  </label>
  <div className="input-group">
    <button 
      type="button" 
      className="btn btn-outline-secondary" 
      onClick={() => bdbIdCardRef.current && bdbIdCardRef.current.click()}
    >
 <i className="fa fa-camera" style={{ marginRight: '8px' }}></i> {/* Adjust the right margin to create space */}
  <span className="slash-style">/</span>
  <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input 
      type="text" 
      className="form-control" 
      readOnly 
      value={formData.bdbIdCardFiles.map(file => file.name).join(', ')} 
      placeholder="No file chosen" 
    />
     {formData.bdbIdCardFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          // Update the form data state to clear the selected files for bdbIdCardFiles
          setFormData({ ...formData, bdbIdCardFiles: [] });
          // Reset the value of the actual file input
          bdbIdCardRef.current.value = null;
        }}
        
        
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input 
      type="file" 
      className="d-none" 
      id="bdbIdCardFiles" 
      name="bdbIdCardFiles" 
      accept="application/pdf, image/jpeg" 
      onChange={handleFileChange} 
      multiple 
      ref={bdbIdCardRef}
    />
  </div>

</div>








<div className="mb-3">
  <label htmlFor="photoFiles" className="form-label">
    8) Colored Photo self (Accept pdf,jpeg and word file, max 15 MB)<span className="required-asterisk" style={{ color: 'red' }}>*</span>
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => photoFilesRef.current && photoFilesRef.current.click()}
    >
       <i className="fa fa-camera" style={{ marginRight: '8px' }}></i> {/* Adjust the right margin to create space */}
  <span className="slash-style">/</span>
  <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>

    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.photoFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.photoFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          // Update the form data state to clear the selected files for bdbIdCardFiles
          setFormData({ ...formData, photoFiles: [] });
          // Reset the value of the actual file input
          photoFilesRef.current.value = null;
        }}
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="photoFiles"
      name="photoFiles"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={photoFilesRef}
    />
  </div>
</div>



        <div className="mb-3">
          <label htmlFor="phoneNumber" className="form-label">9) Phone Number (self):<span className="required-asterisk" style={{ color: 'red' }}>*</span></label>
          <input type="tel" className="form-control" maxLength={15} id="phoneNumber" name="phoneNumber" placeholder='Phone Number' onChange={handleChange} value={formData.phoneNumber} />
        </div>
        <div className="mb-3 row">
  <div className="col-md-4">
    <label htmlFor="phoneNoAlt" className="form-label">
      10) Phone Number (alternate):
    </label>
    <input
      type="tel"
      className="form-control"
      maxLength={15}
      id="phoneNoAlt"
      name="phoneNoAlt"
      placeholder='Phone Number'
      onChange={handleChange}
      value={formData.phoneNoAlt}
    />
  </div>
  <div className="col-md-4">
  <label htmlFor="phoneNoRef1" className="form-label">
    11) Parent Phone Number one:
  </label>
  <input
    type="tel"
    className="form-control"
    maxLength={15}
    placeholder='Phone Number'
    id="phoneNoRef1"
    name="phoneNoRef1"
    
  />
</div>
<div className="col-md-4">
  <label htmlFor="phoneNoRef2" className="form-label">
    12) Parent Phone Number two:
  </label>
  <input
    type="tel"
    className="form-control"
    maxLength={15}
    placeholder='Phone Number'
    id="phoneNoRef2"
    name="phoneNoRef2"
    
  />
</div>

</div>

        <div className="mb-3 row"> {/* Use row to align items in one line */}
  <div className="col-md-4"> {/* Divide the row into three columns */}
    <label htmlFor="phoneNoRef1" className="form-label">13) Reference Phone Number one:</label>
    <input type="tel" className="form-control" maxLength={15} id="phoneNoRef1" name="phoneNoRef1" placeholder='Phone Number'onChange={handleChange} value={formData.phoneNoRef1} />
  </div>
  <div className="col-md-4">
    <label htmlFor="contactPersonName" className="form-label">
    14) Reference Contact Person Name one:
    </label>
    <input type="text" className="form-control"placeholder='Reference Contact Person Name' id="contactPersonName" name="contactPersonName" onChange={handleChange} value={formData.contactPersonName} />
  </div>
  <div className="col-md-4">
    <label htmlFor="companyName" className="form-label">15) Reference Company Name one:</label>
    <input type="text" className="form-control" id="companyName" placeholder='Reference Company Name' name="companyName" onChange={handleChange} value={formData.companyName} />
  </div>
</div>


<div className="mb-3 row"> {/* Use row to align items in one line */}
  <div className="col-md-4">
    <label htmlFor="phoneNoRef2" className="form-label">16) Reference Phone Number two:</label>
    <input type="tel" className="form-control" maxLength={15} id="phoneNoRef2"  placeholder='Phone Number'name="phoneNoRef2" onChange={handleChange} value={formData.phoneNoRef2} />
  </div>
  <div className="col-md-4">
  <label htmlFor="altContactPersonName" className="form-label">17) Reference Contact Person Name two:</label>
  <input type="text" className="form-control" id="altContactPersonName" name="altContactPersonName" placeholder='Reference Contact Person Name' onChange={handleChange} value={formData.altContactPersonName} />
</div>
<div className="col-md-4">
  <label htmlFor="altCompanyName" className="form-label">18) Reference Company Name two:</label>
  <input type="text" className="form-control" id="altCompanyName" name="altCompanyName"  placeholder='Reference  Company Name'onChange={handleChange} value={formData.altCompanyName} />
</div>

</div>



<button type="submit" className="btn custom-submit-btn" disabled={isLoading}>
  {isLoading ? 'Submitting...' : 'Submit KYC'}
</button>

      </form>
      
    </div>
    
  );
}

export default KYCForm;
