import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import './party.css';

import 'bootstrap/dist/css/bootstrap.min.css';

function PartyForm() {
  const [startDate, setStartDate] = useState(new Date());
   const initialFormState = {
    pdfFiles: [],
    aadharCardFiles: [],
    panCardFiles: [],
    passportFiles: [],
    cancelChequeFiles: [],
    gstCertificate: [],
    sign: [], 
    businessCard: [],
    bdbIdCardFiles: [],
    photoFiles: [],
    signatureFiles: [],
    photoIdFiles: [],
    phoneNoRef1: '',
    phoneNoRef2: '',
    phoneNumber1: '',
    phoneNumber2: '',
    emailId:'',
    name: '',
    area: '',
    fax: '',
    qcbNo: '',
    flatNo: '',
    floor: '',
    building: '',
    city: '',
    companyName: '',
    contactPersonName: '',
    cityCode: '',
  officeAddress: '',
  memberRespJewellCouncil: '',
  memberBusinessCouncil: '',
  directorEmail: '',
  companyMobileNumber: '',
  companyDirector: '',
  nativePlace: '',
  district: '',
  samaj: '',
  purchaseRePresenter: '',
  refContactPerson: '',
  referenceCompanyName1: '',
  referenceCompanyName2: '',
  rapnetId: '',
  organizationType: '',
  
 
  };
  const [formData, setFormData] = useState(initialFormState);

  const [selectedDate, setSelectedDate] = useState("");
  const panCardFilesRef = useRef();
  const pdfFilesRef = useRef(null);
  const gstCertificateRef = useRef(null);
  const signFilesRef = useRef(null);
  const photoIdFilesRef = useRef(null);

  const cancelChequeRef = useRef(null);
  const [yearOfEst, setYearOfEst] = useState('');
  const streetNameRef = useRef(null);
  
  const passportFilesRef = useRef();
  const aadharCardFilesRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const businessCardFilesRef = useRef(null);

  

 


  const resetForm = () => {
    setFormData(initialFormState);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };


  const handleRemoveFile = (fileType, index) => {
    // Create a new array without the file at the specified index
    const updatedFiles = formData[fileType].filter((_, fileIndex) => fileIndex !== index);
    setFormData({
      ...formData,
      [fileType]: updatedFiles,
    });
    const fileInput = document.getElementById(fileType);
    if (fileInput) {
      fileInput.value = '';
    }
  };
  const [isLoading, setIsLoading] = useState(false); 
  
    // Add this function to handle the custom button click
    const triggerFileInput = () => {
      cancelChequeRef.current.click();
    };
 
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: [...formData[name], ...Array.from(files)],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); 
    const form = new FormData();

    if (formData.name !== undefined && formData.name !== null && formData.name.trim() !== '') {
      form.append('name', formData.name);
  }
    form.append('phoneNo', formData.phoneNumber1);
    form.append('phoneNoAlt', formData.phoneNumber2);
    form.append('phoneNoRef1', formData.phoneNoRef1);
    form.append('phoneNoRef2', formData.phoneNoRef2);
    form.append('companyName', formData.companyName);
    form.append('contactPersonName', formData.contactPersonName);
    form.append('emailId', formData.email);
    form.append('yearOfEst', yearOfEst);
    form.append('streetName', streetNameRef.current.value);
    form.append('area', formData.area);
    form.append('fax', formData.fax); 
    form.append('qcbNo', formData.qcbNo); 
    form.append('flatNo', formData.flatNo); 
    form.append('floor', formData.floor);
    form.append('building', formData.building);
    form.append('city', formData.city);
    form.append('cityCode', formData.cityCode);
    form.append('officeAddress', formData.officeAddress);
    form.append('memberRespJewellCouncil', formData.memberRespJewellCouncil);
    form.append('memberBusinessCouncil', formData.memberBusinessCouncil);
    form.append('directorEmail', formData.directorEmail);
    form.append('companyMobileNumber', formData.companyMobileNumber);
    form.append('companyDirector', formData.companyDirector);
    form.append('nativePlace', formData.nativePlace);
    form.append('district', formData.district);
    form.append('samaj', formData.samaj);
    form.append('referenceCompanyName1', formData.referenceCompanyName1);
    form.append('referenceCompanyName2', formData.referenceCompanyName2);
    
  form.append('purchaseRePresenter', formData.purchaseRePresenter);
  form.append('rapnetId', formData.rapnetId);
  form.append('organizationType', formData.organizationType);
  form.append('refContactPerson', formData.refContactPerson);
 


 
      

    // Append files to the FormData object
    formData.pdfFiles.forEach(file => {
      form.append('kyc', file); // Changed to `for
    });
    formData.aadharCardFiles.forEach(file => {
      form.append('aadhar', file);
    });
    formData.panCardFiles.forEach(file => {
      form.append('pan', file);
    });
    formData.passportFiles.forEach(file => {
      form.append('passport', file);
    });
    formData.cancelChequeFiles.forEach(file => {
      form.append('cancelCheque', file);
    });
 
    formData.bdbIdCardFiles.forEach(file => {
      form.append('bdbIdCard', file);
    });
    formData.photoIdFiles.forEach(file => {
      form.append('selfPhoto', file);
    });
    formData.gstCertificate.forEach(file => {
      form.append('gstCertificate', file);
    });
    formData.sign.forEach(file => {
      form.append('sign', file);
    });
    formData.businessCard.forEach(file => {
      form.append('businessCard', file);
    });
    
    try {
      const response = await fetch('http://api.aidiamond.in/api/v1/kycParty/add', {
  method: 'POST',
  body: form,

      });

      const result = await response.json(); // Get the response JSON regardless of the response status

      if (response.ok) {
        console.log(result);
        alert(result.message);
        setShowModal(true);
        resetForm();
        window.location.href = '/';
    } else {
        console.error('Error submitting form', result);
        alert(result.message);
    }
} catch (error) {
    console.error('Error submitting form', error);
    alert('There was a problem submitting your KYC.');
} finally {
    setIsLoading(false); 
}
};


 

  return (
    <div className="container mt-5">
      <div className="header">
        <h2>Party KYC Form</h2>
      </div>

      <h6>Note:Section marked with <span className="required-asterisk" style={{ color: 'red' }}>*</span> are mandatory</h6>
      <form onSubmit={handleSubmit}>

      <div className="mb-3">
      <label htmlFor="name" className="form-label">
  1) Name:<span className="required-asterisk" style={{ color: 'red' }}>*</span>
</label>
      <input type="text" className="form-control" maxLength={50} id="name" name="name" placeholder="Name" onChange={handleChange} value={formData.name} />
    </div>

    <div className="row align-items-end"> {/* Align items to the end of the row */}
          <div className="col-md-8">
            <div className="mb-3">
              <label htmlFor="pdfFiles" className="form-label">
                2) KYC File (Download our Form or upload your kyc form )<span className="required-asterisk" style={{ color: 'red' }}>*</span>
              </label>
              <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => pdfFilesRef.current && pdfFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i> {/* Adjust the right margin to create space */}
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.pdfFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.pdfFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, pdfFiles: [] });
          pdfFilesRef.current.value = null;
        }}
        
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="pdfFiles"
      name="pdfFiles"
      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      onChange={handleFileChange}
      multiple
      ref={pdfFilesRef}
    />
  </div>
            </div>
          </div>
          <div className="col-md-4">
          <button
    type="button"
    className="btn custom-download-btn mb-3"
    onClick={() => window.location.href = '/Royal Rays.doc'}
>
    Download File
</button>

          </div>
        </div>

  <div className="mb-3">
  <label htmlFor="aadharCardFiles" className="form-label">
    3) Aadhar Card (Accept pdf,jpeg , max 15 MB)<span className="required-asterisk" style={{ color: 'red' }}>*</span>
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => aadharCardFilesRef.current && aadharCardFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i>
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.aadharCardFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.aadharCardFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, aadharCardFiles: [] });
          aadharCardFilesRef.current.value = null;
        }}
        
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="aadharCardFiles"
      name="aadharCardFiles"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={aadharCardFilesRef}
    />
  </div>
</div>


        <div className="mb-3">
  <label htmlFor="panCardFiles" className="form-label">
    4) Pan Card (Accept pdf,jpeg , max 15 MB):<span className="required-asterisk" style={{ color: 'red' }}>*</span>
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => panCardFilesRef.current && panCardFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i> {/* Adjust the right margin to create space */}
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.panCardFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.panCardFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, panCardFiles: [] });
          panCardFilesRef.current.value = null;
        }}
       
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="panCardFiles"
      name="panCardFiles"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={panCardFilesRef}
    />
  </div>
</div>


        <div className="mb-3">
  <label htmlFor="passportFiles" className="form-label">
    5) PASSPORT (Accept pdf,jpeg , max 15 MB)
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => passportFilesRef.current && passportFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i> {/* Adjust the right margin to create space */}
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.passportFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.passportFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, passportFiles: [] });
          passportFilesRef.current.value = null;
        }}
        // Add space between the text field and button
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="passportFiles"
      name="passportFiles"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={passportFilesRef}
    />
  </div>
</div>





        <div className="mb-3">
  <label htmlFor="cancelChequeFiles" className="form-label">
    6) CANCEL CHEQUE(Accept pdf,jpeg , max 15 MB) <span className="required-asterisk" style={{ color: 'red' }}>*</span>:
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => cancelChequeRef.current && cancelChequeRef.current.click()}
    >
<i className="fa fa-camera" style={{ marginRight: '8px' }}></i> {/* Adjust the right margin to create space */}
  <span className="slash-style">/</span>
  <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i> {/* Add left margin to create space */}
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.cancelChequeFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.cancelChequeFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => setFormData({ ...formData, cancelChequeFiles: [] })}
         
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="cancelChequeFiles"
      name="cancelChequeFiles"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={cancelChequeRef}
    />
  </div>
</div>










<div className="mb-3">
  <label htmlFor="photoIdFiles" className="form-label">
    7) Photo ID of person authorized to collect the Goods (Accept pdf, jpeg , max 15 MB)<span className="required-asterisk" style={{ color: 'red' }}>*</span>:
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => photoIdFilesRef.current && photoIdFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i>
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.photoIdFiles.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.photoIdFiles.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, photoIdFiles: [] });
          photoIdFilesRef.current.value = null;
        }}
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="photoIdFiles"
      name="photoIdFiles"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange} // Make sure to handle the change in your component
      multiple
      ref={photoIdFilesRef} // Make sure to define photoIdFilesRef using useRef
    />
  </div>
</div>

<div className="mb-3">
  <label htmlFor="gstCertificate" className="form-label">
    8) GST Certificate (Accept pdf, jpeg , max 15 MB)<span className="required-asterisk" style={{ color: 'red' }}>*</span>
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => gstCertificateRef.current && gstCertificateRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i>
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.gstCertificate.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.gstCertificate.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, gstCertificate: [] });
          gstCertificateRef.current.value = null;
        }}
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="gstCertificate"
      name="gstCertificate"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={gstCertificateRef}
    />
  </div>
</div>



<div className="mb-3">
  <label htmlFor="signFiles" className="form-label">
    9) Applicant Signature (Accept pdf, jpeg, , max 15 MB)<span className="required-asterisk" style={{ color: 'red' }}>*</span>
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => signFilesRef.current && signFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i>
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.sign.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.sign.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, sign: [] });
          signFilesRef.current.value = null;
        }}
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="signFiles"
      name="sign"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={signFilesRef}
    />
  </div>
</div>
<div className="mb-3">
  <label htmlFor="businessCardFiles" className="form-label">
   10) Business Card (Accept pdf, jpeg file, max 15 MB)<span className="required-asterisk" style={{ color: 'red' }}>*</span>
  </label>
  <div className="input-group">
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => businessCardFilesRef.current && businessCardFilesRef.current.click()}
    >
      <i className="fa fa-camera" style={{ marginRight: '8px' }}></i>
      <span className="slash-style">/</span>
      <i className="fa fa-upload" style={{ marginLeft: '8px' }}></i>
    </button>
    <input
      type="text"
      className="form-control"
      readOnly
      value={formData.businessCard.map(file => file.name).join(', ')}
      placeholder="No file chosen"
    />
    {formData.businessCard.length > 0 && (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={() => {
          setFormData({ ...formData, businessCard: [] });
          businessCardFilesRef.current.value = null;
        }}
      >
        <i className="fa fa-times"></i>
      </button>
    )}
    <input
      type="file"
      className="d-none"
      id="businessCardFiles"
      name="businessCard"
      accept="application/pdf, image/jpeg"
      onChange={handleFileChange}
      multiple
      ref={businessCardFilesRef}
    />
  </div>
</div>




<div className="box-container">

<h4 className="mb-3 header-style">Company Details <span className="required-asterisk" style={{ color: 'red' }}>*</span>:</h4>
  <div className="row">
    <div className="col-md-4 mb-3">
      <label htmlFor="registeredCompanyName">Registered Company Name: </label>
      <input
  type="text"
  className="form-control"
  id="registeredCompanyName"
  name="companyName" 
  placeholder="Registered Company Name"
  value={formData.companyName}
  onChange={handleChange}
/>
    </div>
    <div className="col-md-4 mb-2">
    <label htmlFor="phoneNumber1">Phone Number 1:</label>
    <input
  type="tel"
  className="form-control"
  id="phoneNumber1"
  placeholder="Phone Number 1"
  value={formData.phoneNumber1} // Make sure this is set
  onChange={e => setFormData({ ...formData, phoneNumber1: e.target.value })} // Updated
/>
  </div>
  
  <div className="col-md-4 mb-3">
      <label htmlFor="email">Company Email:<span className="required-asterisk" style={{ color: 'red' }}>*</span></label>
      <input
  type="email"
  className="form-control"
  id="email"
  name="email" // This should match the property in your state
  placeholder="Email"
  value={formData.email}
  onChange={handleChange}
/>
    </div>
  </div>
  <div className="row">
    
    <div className="col-md-4 mb-2">
      <label htmlFor="area">Area:</label>
      <input
  type="text"
  className="form-control"
  id="area"
  name="area"
  placeholder="Area"
  value={formData.area}
  onChange={handleChange} // Assuming handleChange updates the formData state
/>
    </div>
    <div className="col-md-4 mb-2">
      <label htmlFor="fax">Fax:</label>
      <input
  type="text"
  className="form-control"
  id="fax"
  name="fax"
  placeholder="Fax"
  value={formData.fax}
  onChange={handleChange} // Make sure this function updates the formData state
/>
    </div>
    <div className="col-md-4 mb-2">
      <label htmlFor="qbcNo">QBC No:</label>
      <input
  type="text"
  className="form-control"
  id="qcbNo"
  name="qcbNo"
  placeholder="QBC No"
  value={formData.qcbNo}
  onChange={handleChange} // Use the existing handleChange function
/>
    </div>
  </div>
  <div className="row">
    <div className="col-md-4 mb-2">
      <label htmlFor="officeFlatNo">Office/Flat No.:</label>
      <input
  type="text"
  className="form-control"
  id="flatNo"
  name="flatNo"
  placeholder="Office/Flat No"
  value={formData.flatNo}
  onChange={handleChange} // Use the existing handleChange function
/>
    </div>
    

    <div className="col-md-4 mb-2">
      <label htmlFor="floor">Floor:</label>
      <input
  type="text"
  className="form-control"
  id="floor"
  name="floor"
  placeholder="Floor"
  value={formData.floor}
  onChange={handleChange}
/>
    </div>

    <div className="col-md-4 mb-2">
      <label htmlFor="building">Building:</label>
      <input
  type="text"
  className="form-control"
  id="building"
  name="building"
  placeholder="Building"
  value={formData.building}
  onChange={handleChange}
/>
    </div>
    </div>
    <div className="row">
  <div className="col-md-4 mb-2">
    <label htmlFor="city">City:</label>
    <input
  type="text"
  className="form-control"
  id="city"
  name="city"
  placeholder="City"
  value={formData.city}
  onChange={handleChange}
/>
  </div>
  <div className="col-md-4 mb-2">
    <label htmlFor="pinCode">Pin Code:</label>
    <input type="text" className="form-control" id="pinCode" placeholder="Pin Code" />
  </div>
  <div className="col-md-4 mb-2">
    <label htmlFor="state">State:</label>
    <input type="text" className="form-control" id="state" placeholder="State" />
  </div>
</div>
<div className="row">
<div className="col-md-4 mb-3">
  <label htmlFor="yearOfEstablishment">Year of Establishment:</label>
  <input 
  type="date" 
  className="form-control" 
  id="yearOfEstablishment" 
  name="yearOfEst" // Added name attribute for consistency
  placeholder="YYYY"
  value={yearOfEst} // Controlled component
  onChange={(e) => setYearOfEst(e.target.value)} // Update state
/>
</div>
  <div className="col-md-4 mb-2">
    <label htmlFor="phoneNumber2">Alternate Phone Number :</label>
    <input
  type="tel"
  className="form-control"
  id="phoneNumber2"
  placeholder="Phone Number 2"
  value={formData.phoneNumber2} // Make sure this is set
  onChange={e => setFormData({ ...formData, phoneNumber2: e.target.value })} // Updated
/>
  </div>
  <div className="col-md-4 mb-2">
    <label htmlFor="cityCode">City Code:</label>
    <input
  type="text"
  className="form-control"
  id="cityCode"
  name="cityCode"
  placeholder="City Code"
  value={formData.cityCode}
  onChange={handleChange}
/>
  </div>

  <div className="col-md-4 mb-3">
    <label htmlFor="streetNoName">Street No/Name:</label>
    <input
  type="text"
  className="form-control"
  id="streetNoName"
  name="streetName"
  placeholder="Street No/Name"
  ref={streetNameRef}
/>
  </div>
    <div className="col-md-4 mb-3">
    <label htmlFor="officeAddress">Office Address (if other than above):</label>
    <input
  type="text"
  className="form-control"
  id="officeAddress"
  name="officeAddress"
  placeholder="Office Address (if other than above)"
  value={formData.officeAddress}
  onChange={handleChange}
/>
  </div>

  <div className="col-md-4 mb-3">
    <label htmlFor="rjcMember">Member of Responsible Jewellery Council?</label>
    <input
  type="text"
  className="form-control"
  id="memberRespJewellCouncil"
  name="memberRespJewellCouncil"
  placeholder="If yes then specify"
  value={formData.memberRespJewellCouncil}
  onChange={handleChange}
/>

  </div>

  <div className="col-md-4 mb-3">
    <label htmlFor="businessOrgMember">Member of any business organization / Council?</label>
    <input
  type="text"
  className="form-control"
  id="memberBusinessCouncil"
  name="memberBusinessCouncil"
  placeholder="If yes then specify"
  value={formData.memberBusinessCouncil}
  onChange={handleChange}
/>
  </div>
  <div className="col-md-4 mb-3">
    <label htmlFor="rapnetId">Rapnet ID :</label>
    <input
      type="text"
      className="form-control"
      id="rapnetId"
      name="rapnetId"
      placeholder="Rapnet ID"
      value={formData.rapnetId}
      onChange={handleChange}
    />
  </div>
  <div className="col-md-4 mb-3">
    <label htmlFor="organizationType">Organization Type:</label>
    <input
      type="text"
      className="form-control"
      id="organizationType"
      name="organizationType"
      placeholder="Organization Type"
      value={formData.organizationType}
      onChange={handleChange}
    />
  </div>

</div>
  </div>

  <div className="container-box">
  <h4 className="mb-3">Company Director / CEO / Partner / CFO / Owner Details <span className="required-asterisk" style={{ color: 'red' }}>*</span>:</h4>

  <div className="row">
    <div className="col-md-4 mb-3">
      <label htmlFor="companyDirector">Company Director:</label>
      <input
  type="text"
  className="form-control"
  id="companyDirector"
  name="companyDirector"
  placeholder="Company Director"
  value={formData.companyDirector}
  onChange={handleChange}
/>
    </div>
    <div className="col-md-4 mb-3">
      <label htmlFor="mobileNumber">Mobile Number:</label>
      <input
  type="tel"
  className="form-control"
  id="companyMobileNumber"
  name="companyMobileNumber"
  placeholder="Mobile Number"
  value={formData.companyMobileNumber}
  onChange={handleChange}
/>
    </div>
    <div className="col-md-4 mb-3">
      <label htmlFor="email">Email:</label>
      <input
  type="email"
  className="form-control"
  id="directorEmail"
  name="directorEmail"
  placeholder="Email"
  value={formData.directorEmail}
  onChange={handleChange}
/>
    </div>
  </div>
  <div className="row">
    <div className="col-md-4 mb-3">
      <label htmlFor="nativePlace">Native Place:</label>
      <input
  type="text"
  className="form-control"
  id="nativePlace"
  name="nativePlace"
  placeholder="Native Place"
  value={formData.nativePlace}
  onChange={handleChange}
/>
    </div>
    <div className="col-md-4 mb-3">
      <label htmlFor="district">District:</label>
      <input
  type="text"
  className="form-control"
  id="district"
  name="district"
  placeholder="District"
  value={formData.district}
  onChange={handleChange}
/>
    </div>
    <div className="col-md-4 mb-3">
      <label htmlFor="samaj">Samaj:</label>
      <input
  type="text"
  className="form-control"
  id="samaj"
  name="samaj"
  placeholder="Samaj"
  value={formData.samaj}
  onChange={handleChange}
/>
    </div>
  </div>
  <div className="row">
    <div className="col-12 mb-3">
      <label htmlFor="purchaseRepresentative">Purchase Representative:</label>
      <input
  type="text"
  className="form-control"
  id="purchaseRepresentative"
  name="purchaseRePresenter"
  placeholder="Purchase Representative"
  value={formData.purchaseRePresenter}
  onChange={handleChange}
/>
    </div>
  </div>
</div>
<div className="container-box">
  <h4 className="mb-3">Reference Details <span className="required-asterisk" style={{ color: 'red' }}>*</span>:</h4>
  <div className="row">
  <div className="col-md-4 mb-3">
      <label htmlFor="referenceCompanyName1">Reference Company Name 1:</label>
      <input
  type="text"
  className="form-control"
  id="referenceCompanyName1"
  name="referenceCompanyName1"
  placeholder="Reference Company Name 1"
  value={formData.referenceCompanyName1}
  onChange={handleChange}
/>
    </div>
  <div className="col-md-4 mb-3">
      <label htmlFor="referencePhoneNo1">Reference Phone Number one:</label>
      <input
  type="tel"
  className="form-control"
  id="referencePhoneNo1"
  name="phoneNoRef1" // Matches the formData property
  placeholder="Reference Phone Number 1"
  value={formData.phoneNoRef1}
  onChange={handleChange}
/>
    </div>
    <div className="col-md-4 mb-3">
      <label htmlFor="referenceContactName1">Reference Contact Person Name one:</label>
      <input
  type="text"
  className="form-control"
  id="referenceContactName1"
  name="contactPersonName" // This should match the formData property
  placeholder="Reference Contact Person Name 1"
  value={formData.contactPersonName}
  onChange={handleChange}
/>

    </div>

    
  </div>
 
  <div className="row">
  <div className="col-md-4 mb-3">
      <label htmlFor="referenceCompanyName2">Reference Company Name 2:</label>
      <input
  type="text"
  className="form-control"
  id="referenceCompanyName2"
  name="referenceCompanyName2"
  placeholder="Reference Company Name 2"
  value={formData.referenceCompanyName2}
  onChange={handleChange}
/>
    </div>
    
  <div className="col-md-4 mb-3">
      <label htmlFor="referencePhoneNo2">Reference Phone Number two:</label>
      <input
  type="tel"
  className="form-control"
  id="referencePhoneNo2"
  name="phoneNoRef2" 
  placeholder="Reference Phone Number 2"
  value={formData.phoneNoRef2}
  onChange={handleChange}
/>
    </div>
 

    <div className="col-md-4 mb-3">
      <label htmlFor="referenceContactName2">Reference Contact Person Name two:</label>
      <input
  type="text"
  className="form-control"
  id="referenceContactName2"
  name="refContactPerson"
  placeholder="Reference Contact Person Name two"
  value={formData.refContactPerson}
  onChange={handleChange}
/>
    </div>


  

   
</div>
</div>

 


 
  



 


 



<button type="submit" className="btn custom-submit-btn" disabled={isLoading}>
  {isLoading ? 'Submitting...' : 'Submit KYC'}
</button>

<div className="terms-and-conditions">
<h4 className="terms-header">Terms & Conditions:</h4>
  <div className="terms-content">
    <h5>1) General Information:</h5>
    <p><strong>a)</strong> Diamonds purchased/agreed to be purchased or confirmed, cannot be cancelled, returned or exchanged.</p>
    <p><strong>b)</strong> Invoice will be raised only in the name in which diamonds are confirmed for purchase/requested for consignment by the client.</p>
    <p><strong>c)</strong> Goods invoiced after 7 days of confirmation will be charged at 9% p.a. respectively. This is subject to change without any prior intimation.</p>
    <p><strong>d)</strong> The person Shall be responsible for reimbursing the cost of the damages caused to the diamond/s while viewing in AI DIAMOND  premises.</p>
    
    <h5>2) Payment Incentive System:</h5>
    <p><strong>a)</strong> An incentive @ 6% p.a. and interest @ 9% p.a. will be given or charged for early/delay payment respectively.</p>
    <p><strong>b)</strong> The above amount, if applicable, will be incorporated in your next invoice by way of charge or credit.</p>
    <p><strong>c)</strong> Remittance should be made from the same company on which the invoice is raised.</p>

    <h5>3) Update:</h5>
    <p>Clients are required to inform AI DIAMOND  of any changes in details of your Know Your Customer (KYC) form within 30 days from the date of such changes.</p>

    <h5>4) Disclaimer:</h5>
    <p><strong>a)</strong> AI DIAMOND  does not guarantee that the grading results of the diamond sold necessarily matches with those of any of the external laboratories.</p>
    <p><strong>b)</strong> Rap % wherever indicated are for reference purpose only.</p>
    <p><strong>c)</strong> The prices are always mentioned as ‘per carat price’.</p>
    <p><strong>d)</strong> Certain terms implicit and inbuilt into our systems, as per the criteria defined therein form part of the terms of sale.</p>
    <p><strong>e)</strong> The terms mentioned in this document, under “Terms & Conditions” are subject to changes without any prior written notice.</p>
  </div>
</div>

      </form>
    </div>
  );
}

export default PartyForm;
