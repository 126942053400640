import React, { useState } from 'react';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleKYCFormClick = () => {
    navigate('/kyc');
  };
  const handlePartyFormClick = () => {
    navigate('/party'); 
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === 'admin' && password === 'admin') {
      navigate('/admin'); // Navigate to Admin component
    } else if (username === 'party' && password === 'party') {
      navigate('/padmin'); // Navigate to Padmin component
    } else {
      alert('Invalid credentials');
    }
  };
  const isLoginEnabled = () => {
    return (username === 'admin' && password === 'admin') || (username === 'party' && password === 'party');
  };
  

  return (
    <section className="h-100 gradient-form" style={{ backgroundColor: "#eee" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-10">
            <div className="card rounded-3 text-black">
              <div className="row g-0">
                <div className="col-lg-6">
                  <div className="card-body p-md-5 mx-md-4">
                    <div className="text-center">
                      <img
                        src="/PNG-AI.png"
                        style={{ width: 185 }}
                        alt="logo"
                      />
                    </div>
                    <form onSubmit={handleSubmit}>
                    <div className="form-outline mb-4">
  <label className="form-label" htmlFor="form2Example11">
    Username
  </label>
  <div className="d-flex align-items-center">
    <input
      type="text"
      id="form2Example11"
      className="form-control"
      placeholder="username"
      value={username}
      onChange={(e) => setUsername(e.target.value)}
    />
  </div>
</div>
<div className="form-outline mb-4">
  <label className="form-label" htmlFor="form2Example22">
    Password
  </label>
  <div className="d-flex align-items-center">
    <input
      type="password"
      id="form2Example22"
      className="form-control"
      placeholder="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
    />
  </div>
</div>
<div className="login__button-container text-center pt-1 mb-5 pb-1">
<button
  className="login__button login__button--primary mb-3 me-2"
  type="submit"
  disabled={!isLoginEnabled()}
>
  Log in
</button>


    <button
    className="login__button login__button--secondary mb-3 me-2"
    type="button"
    onClick={handleKYCFormClick}
  >
    BROKER KYC Form
  </button>
  <button
    className="login__button login__button--secondary mb-3"
    type="button"
    onClick={handlePartyFormClick}
  >
    Party KYC Form
  </button>

</div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;

