// App.js
import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import KYC from './kyc';
import Admin from './admin';
import PartyForm from './party';
import Padmin from './padmin';
function App() {
  return (
    <div className="App">
     
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/kyc" element={<KYC />} />
          <Route path="/party" element={<PartyForm />} />
          <Route path="/padmin" element={<Padmin />} />
        </Routes>
      
    </div>
  );
}

export default App;


