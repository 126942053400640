import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaSearch } from 'react-icons/fa'; 
import './admin.css';


const Admin = () => {
  const [view, setView] = useState('pending');
  const [searchQuery, setSearchQuery] = useState('');
  const [kycSubmissions, setKycSubmissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [isKYCChecked, setIsKYCChecked] = useState(false);
  const [isAadharChecked, setIsAadharChecked] = useState(false);
 const [isPanChecked, setIsPanChecked] = useState(false);
 const [isCancelChequeChecked, setIsCancelChequeChecked] = useState(false);
 const [isColoredPhotoChecked, setIsColoredPhotoChecked] = useState(false);

 const isSubmitEnabled = () => {
  return isKYCChecked && isAadharChecked && isPanChecked && isCancelChequeChecked && isColoredPhotoChecked;
};

  const getPath = (submission, docType) => {
    // Use "selfPhoto" instead of "coloredPhoto" if that's what the documents array uses
    const docTypeKey = docType === "coloredPhoto" ? "selfPhoto" : docType;
  
    const doc = submission.kycDocuments.find(d => d.docType === docTypeKey);
    return doc ? `https://api.aidiamond.in/api/v1/kyc/${submission._id}/downloadDocs/${doc._id}` : '';
  };
  const handleSearch = () => {
    setCurrentPage(1);
    const filters = searchQuery ? { name: searchQuery } : { status: view };
    fetchKYCData(1, filters);
  };
  const setViewFilter = (status) => {
    setCurrentPage(1);
    const filters = { status };
    fetchKYCData(1, filters);
    setView(status);
  };


const openVerifyModal = (submission) => {
  setSelectedSubmission(submission);
  setShowVerifyModal(true);
};
const handleChange = (e) => {
  if (e.key === 'Enter') {
    handleSearch();
  } else {
    setSearchQuery(e.target.value);
  }
};


const fetchKYCData = async (page, filters) => {
  try {
    const url = `https://api.aidiamond.in//api/v1/kyc?page=${page}&limit=${itemsPerPage}`;
   
  
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ filters }),
    };

    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    setKycSubmissions(data.data);
    setTotalSubmissions(data.totalCount);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

useEffect(() => {
  const filters = searchQuery ? { name: searchQuery } : { status: view };
  fetchKYCData(currentPage, filters);
}, [currentPage, view, searchQuery]);

  const handleDownloadDocs = async (submissionId) => {
    try {
      // Trigger file download using the browser
      // This assumes the server sets the Content-Disposition header to attachment.
      window.open(`https://api.aidiamond.in//api/v1/kyc/${submissionId}/downloadDocs`, '_blank');
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = new URL('http://api.aidiamond.in/api/v1/kyc');
        const params = { page: currentPage.toString(), limit: itemsPerPage.toString() };
        url.search = new URLSearchParams(params).toString();
  
        const options = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ filters: { status: view } }),
        };
  
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setKycSubmissions(data.data);
        setTotalSubmissions(data.totalCount);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [currentPage, view, itemsPerPage]);

  
  



  const filteredSubmissions = kycSubmissions
.filter(submission => submission.kycStatus === view)
.filter(submission => submission.name.toLowerCase().includes(searchQuery.toLowerCase()));

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = filteredSubmissions.slice(indexOfFirstItem, indexOfLastItem);


const paginate = async (pageNumber) => {
  setCurrentPage(pageNumber);
  window.scrollTo({ top: 0, behavior: 'smooth' });
  // Ensure that only the name is passed if it is present, otherwise pass the status
  const filters = searchQuery ? { name: searchQuery } : { status: view };
  await fetchKYCData(pageNumber, filters);
};
const totalPages = Math.ceil(totalSubmissions / itemsPerPage);
const pageNumbers = [];
for (let i = 1; i <= totalPages; i++) {
  pageNumbers.push(i);
}


  const updateSubmissionStatus = (submissionId, newStatus) => {
    setKycSubmissions(kycSubmissions.map(submission => 
      submission.id === submissionId ? { ...submission, status: newStatus } : submission
    ));
  };

  const handleAccept = async (submissionId) => {
    try {
      const formData = new FormData();
      formData.append('status', 'approved');
  
      const response = await fetch(`https://api.aidiamond.in/api/v1/kyc/statusUpdate/${submissionId}`, {
        method: 'PUT',
        body: formData
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result); // You can handle the response here
  
      // Fetch the updated list of submissions
      
      fetchKYCData();
      window.location.reload();
    } catch (error) {
      console.error('Error updating status:', error);
    }
    
  };
  
  
  const handleReject = async (submissionId) => {
    try {
      const formData = new FormData();
      formData.append('status', 'rejected');
  
      const response = await fetch(`https://api.aidiamond.in/api/v1/kyc/statusUpdate/${submissionId}`, {
        method: 'PUT',
        body: formData
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result); // You can handle the response here
  
      // Update the local state to reflect the change
      updateSubmissionStatus(submissionId, 'rejected');
      fetchKYCData();
      window.location.reload();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  
 

  return (
    <div className="admin-container mt-5">
      <h1 className="mb-4 text-center">KYC BROKER</h1>
      <div className="mb-3 d-flex justify-content-center search-bar">
    <div className="search-field">
    <input
  type="text"
  className="form-control"
  placeholder="Search by name..."
  value={searchQuery}
  onChange={e => setSearchQuery(e.target.value)}
  onKeyPress={handleChange}
/>
<button className="search-icon-btn" onClick={handleSearch}>
  <FaSearch />
</button>
    </div>
  </div>

      <div className="mb-3 d-flex justify-content-center">

    
    <button className="btn btn-primary me-2" onClick={() => setViewFilter('pending')}>KYC Pending</button>
    <button 
    className="btn custom-green-button margin-right" 
    onClick={() => setViewFilter('approved')}
>
    KYC Approved
</button>

<button 
    className="btn custom-red-button" 
    onClick={() => setViewFilter('rejected')}
>
    KYC Rejected
</button>






      </div>

      <div className="table-responsive">
          <table className="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>Date</th>
              <th>Actions</th>
             
            </tr>
          </thead>
            <tbody>
  {kycSubmissions.map(submission => (
    <tr key={submission._id}>
     
      <td>{submission.name}</td>
      <td>{submission.phoneNumber}</td>
      <td>{submission.kycStatus}</td>
      <td>{new Date(submission.createdAt).toLocaleDateString()} {/* Format the date */}</td>
      <td>
                {submission.kycStatus.toLowerCase() === 'pending' && (
                  <>
                   
                   <button
  className="btn custom-verify-button"
  onClick={() => openVerifyModal(submission)}
  style={{ marginRight: '10px' }}
>
  Verify
</button>

                  </>
                )}
             <button className="btn btn-info" onClick={() => handleDownloadDocs(submission._id)}>
  Download KYC
</button>

              </td>
    </tr>
    
  ))}
</tbody>
          </table>
          <nav>
          <ul className="pagination">
          {pageNumbers.map(number => (
        <li key={number} className="page-item">
          <button onClick={() => paginate(number)} className="page-link">
            {number}
          </button>
    </li>
  ))}
</ul>
</nav>
        </div>
        {showVerifyModal && (
 <div className="modal show" style={{ display: "block" }} tabIndex="-1">
 <div className="modal-dialog">
   <div className="modal-content">
     <div className="modal-header">
       <h5 className="modal-title">Verify KYC Submission</h5>
       <button type="button" className="btn-close" onClick={() => setShowVerifyModal(false)}></button>
     </div>
     <div className="modal-body">
       {selectedSubmission && (
         <div>
           <h6>Applicant Details:</h6>
           <p><strong>Name:</strong> {selectedSubmission.name}</p>
           <p><strong>Phone Number:</strong> {selectedSubmission.phoneNumber}</p>
           
           <h6>Reference 1:</h6>
           <p><strong>Contact Person Name:</strong> {selectedSubmission.contactPersonName}</p>
           <p><strong>Company Name:</strong> {selectedSubmission.companyName}</p>
           <p><strong>Phone Number:</strong> {selectedSubmission.phoneNumberRef1}</p>

          
           <h6>Reference 2:</h6>
              <p><strong>Contact Person Name:</strong> {selectedSubmission.altContactPersonName || 'N/A'}</p>
              <p><strong>Company Name:</strong> {selectedSubmission.altCompanyName || 'N/A'}</p>
              <p><strong>Phone Number:</strong> {selectedSubmission.phoneNumberRef2}</p>
              {/* KYC File */}
              <div className="mb-3">
                <label htmlFor="kycCheck" className="form-label">KYC File</label>
                <div className="input-group">
                  <div className="input-group-text">
                    <input className="form-check-input mt-0" type="checkbox" id="kycCheck" onChange={(e) => setIsKYCChecked(e.target.checked)} />
                  </div>
                  <input type="text" className="form-control" readOnly value={getPath(selectedSubmission, "kyc")} />
                  <a href={getPath(selectedSubmission, "kyc")} target="_blank" className="btn btn-outline-secondary">
                    <i className="bi bi-eye"></i>
                  </a>
                </div>
              </div>
              {/* Aadhar Card */}
              <div className="mb-3">
                <label htmlFor="aadharCheck" className="form-label">Aadhar Card</label>
                <div className="input-group">
                  <div className="input-group-text">
                    <input className="form-check-input mt-0" type="checkbox" id="aadharCheck" onChange={(e) => setIsAadharChecked(e.target.checked)} />
                  </div>
                  <input type="text" className="form-control" readOnly value={getPath(selectedSubmission, "aadhar")} />
                  <a href={getPath(selectedSubmission, "aadhar")} target="_blank" className="btn btn-outline-secondary">
                    <i className="bi bi-eye"></i>
                  </a>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="panCheck" className="form-label">PAN Card</label>
                <div className="input-group">
                  <div className="input-group-text">
                    <input className="form-check-input mt-0" type="checkbox" id="panCheck" onChange={(e) => setIsPanChecked(e.target.checked)} />
                  </div>
                  <input type="text" className="form-control" readOnly value={getPath(selectedSubmission, "pan")} />
                  <a href={getPath(selectedSubmission, "pan")} target="_blank" className="btn btn-outline-secondary">
                    <i className="bi bi-eye"></i>
                  </a>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="passportCheck" className="form-label">Passport</label>
                <div className="input-group">
                  <div className="input-group-text">
                    <input className="form-check-input mt-0" type="checkbox" id="passportCheck"  />
                  </div>
                  <input type="text" className="form-control" readOnly value={getPath(selectedSubmission, "passport")} />
                  <a href={getPath(selectedSubmission, "passport")} target="_blank" className="btn btn-outline-secondary">
                    <i className="bi bi-eye"></i>


                  </a>

                  
                </div>
                <div className="mb-3">
                <label htmlFor="cancelChequeCheck" className="form-label">Cancelled Cheque</label>
                <div className="input-group">
                  <div className="input-group-text">
                    <input className="form-check-input mt-0" type="checkbox" id="cancelChequeCheck" onChange={(e) => setIsCancelChequeChecked(e.target.checked)} />
                  </div>
                  <input type="text" className="form-control" readOnly value={getPath(selectedSubmission, "cancelCheque")} />
                  <a href={getPath(selectedSubmission, "cancelCheque")} target="_blank" className="btn btn-outline-secondary">
                    <i className="bi bi-eye"></i>
                  </a>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="bdbIdCardCheck" className="form-label">BDB ID Card</label>
                <div className="input-group">
                  <div className="input-group-text">
                    <input className="form-check-input mt-0" type="checkbox" id="bdbIdCardCheck" />
                  </div>
                  <input type="text" className="form-control" readOnly value={getPath(selectedSubmission, "bdbIdCard")} />
                  <a href={getPath(selectedSubmission, "bdbIdCard")} target="_blank" className="btn btn-outline-secondary">
                    <i className="bi bi-eye"></i>
                  </a>
                </div>
              </div>
              <div className="mb-3">
              {console.log('Path for colored photo:', getPath(selectedSubmission, "coloredPhoto"))}
                <label htmlFor="coloredPhotoCheck" className="form-label">Colored Photo</label>
                <div className="input-group">
                  <div className="input-group-text">
                    <input className="form-check-input mt-0" type="checkbox" id="coloredPhotoCheck" onChange={(e) => setIsColoredPhotoChecked(e.target.checked)}/>
                  </div>
                  <input type="text" className="form-control" readOnly value={getPath(selectedSubmission, "coloredPhoto")} />

                  <a href={getPath(selectedSubmission, "coloredPhoto")} target="_blank" className="btn btn-outline-secondary">
                    <i className="bi bi-eye"></i>
                  </a>
                </div>
              </div>
              </div>
              
            </div>
          )}
        </div>
        <div className="modal-footer">
        <button 
  type="button" 
  className="btn button-accept" 
  disabled={!isSubmitEnabled()} // Disable if not all required checkboxes are checked
  onClick={() => {
    handleAccept(selectedSubmission._id);
    setShowVerifyModal(false);
  }}
>
  Accept
</button>
<button 
  type="button" 
  className="btn button-reject" 
  disabled={!isSubmitEnabled()} // Disable if not all required checkboxes are checked
  onClick={() => {
    handleReject(selectedSubmission._id);
    setShowVerifyModal(false);
  }}
>
  Reject
</button>

</div>

      </div>
    </div>
  </div>
)}
      </div>

  );
};

export default Admin;
